<template>
	<b-card title="Rekomendasi Pajak Text List">
		<b-row class="mb-2">
			<b-col cols="12" md="6">
				
			</b-col>
			<b-col cols="12" md="6">
				<form class="form-inline justify-content-end">
					<div class="d-flex">
						<div class="position-relative">
							<input 
								type="search"
								class="form-control"
								v-model="filter.keyword" 
								placeholder="Search: Text" 
								aria-label="Search"
							>
						</div>
						<button 
							:disabled="!filter.keyword" 
							@click="filter.keyword = ''" 
							class="btn btn-outline-secondary ml-sm-2"
						>
							Reset
						</button>
					</div>
				</form>
			</b-col>
		</b-row>
		<b-table 
			striped 
			hover 
			:fields="fields"
			:items="compliting"
			:busy="isLoading"
			show-empty
			responsive
		>
			<template #table-busy>
				<div class="text-center text-secondary my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Loading...</strong>
				</div>
			</template>

			<template v-slot:cell(no)="{ index }">
				{{ (currentPage - 1) * 10 + index + 1 }}
			</template>

			<template v-slot:cell(actions)="{ item }">
				<b-badge 
					variant="warning"
					class="badge-glow"
				>
					<router-link
						:to="'/edit-compliting-profile/'+ item.uuid"
						v-if="checkPermission('update assessment question')"
					>
						<feather-icon
							:id="`invoice-row-${item.uuid}-send-icon`"
							icon="EditIcon"
							class="cursor-pointer"
							size="16"
							v-ripple.400="'rgba(113, 102, 240, 0.15)'"
							v-b-tooltip.hover.bottom="'Edit Data'"
						/>
					</router-link>
				</b-badge>

			</template>
		</b-table>
		<div
			v-if="result.total > 0"
			class="m-1"
		>
			<div class="row">
				<div class="col mb-1">
					<small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
				</div>
				<div class="col">
					<pagination
						:data="result"
						:limit="4"
						align="right"
						@pagination-change-page="getData"
					/>
				</div>
			</div>
		</div>
	</b-card>
</template>

<script>
import { 
	BCard,
	BBadge,
	BCol,
	BRow,
	BCardText,
	BTable,
	BPagination,
	BSpinner, 
	VBTooltip
} from 'bootstrap-vue'
import { checkPermission } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import _ from 'lodash'
export default {
	components: {
		BCard,
		BBadge,
		BCol,
		BRow,
		BCardText,
		BTable,
		BPagination,
		BSpinner,
	},
	directives: {
	'b-tooltip': VBTooltip,
		Ripple,
	},
	watch: {
		filter: {
			handler: _.debounce(function () {
				this.getData()
			}, 300),
			deep: true,
		},
	},
	setup(props) {
		return {
			checkPermission,
		}
	},
	computed: {
		rows() {
			return this.compliting.length
		}
	},
	data() {
		return {
			currentPage: 1,
			result:{},
			isLoading: false,
			fields: ['no', 'text', 'actions'],
			compliting:[],
			filter: {
				keyword: ''
			},
		}
	},

	methods: {
		getData(page) {
			this.isLoading = true
			const queryParams = this.filter
			queryParams.per_page = 10
			queryParams.page = page
			this.$http.get('admin/chatbot/compliting-profile', {
				params: queryParams,
			})
				.then(response => {
				this.result = response.data.data
				this.compliting = response.data.data.data
				this.currentPage = response.data.data.current_page
				this.isLoading = false
			})
		}
	},

	created() {
		this.getData();
	}
}
</script>